<template>
  <div>
    <h1>Statistiken</h1>
    <v-card :loading="loading && !summaries">
      <v-tabs v-model="period">
        <v-tab :key="0">
          Woche
        </v-tab>
        <v-tab :key="1">
          Monat
        </v-tab>
        <v-tab :key="2">
          Jahr
        </v-tab>
      </v-tabs>
      <v-card flat>
        <v-card-text class="pt-0">
          <div class="period-navigation">
            <v-btn
              icon
              @click="selectedPeriod += 1"
              :disabled="
                selectedPeriod >=
                  (period === 0 ? weeks.length : period === 1 ? months.length : years.length) - 1
              "
              ><v-icon>chevron_left</v-icon></v-btn
            >
            <v-select
              :loading="loading"
              hide-details
              label="Zeitraum"
              solo
              item-text="text"
              item-value="id"
              flat
              :items="period === 0 ? weeks : period === 1 ? months : years"
              v-model="selectedPeriod"
            ></v-select>
            <v-btn
              @click="selectedPeriod -= 1"
              :disabled="selectedPeriod === 0"
              icon
              ><v-icon>chevron_right</v-icon></v-btn
            >
          </div>
          <div class="mb-5">
            <div
              @click="showEarnings = !showEarnings"
              :style="{
                float: 'right',
                cursor: 'pointer',
                textDecoration: showEarnings ? null : 'line-through',
                textAlign: 'right',
              }"
            >
              Einnahmen: {{ formatEarnings(totalEarnings, 0) }} €<br />
              <small>
                Tagesdurschnitt:
                {{ formatEarnings(dailyAverageEarnings, 0) }} € </small
              ><br />
              <small
                :style="{
                  color:
                    overearnings < 0 ? this.undertimeColor : this.overtimeColor,
                }"
              >
                Über/Unter Soll: {{ formatEarnings(overearnings, 0) }} €
              </small>
            </div>
            <div
              @click="showDuration = !showDuration"
              :style="{
                cursor: 'pointer',
                textDecoration: showDuration ? null : 'line-through',
              }"
            >
              Arbeitszeit:
              {{ formatSeconds(totalDuration).substring(0, 5) }} Stunden<br />
              <small>
                Tagesdurschnitt:
                {{
                  formatSeconds(dailyAverageDuration).substring(0, 5)
                }} </small
              ><br />
              <small
                :style="{
                  color:
                    overtime < 0 ? this.undertimeColor : this.overtimeColor,
                }"
              >
                Überstunden: {{ formatSeconds(overtime) }}
              </small>
            </div>
          </div>
          <BarChart
            :key="barChartKey"
            :chartdata="barChartdata"
            :options="chart_options"
          ></BarChart>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<style scoped lang="scss">
.period-navigation {
  display: flex;
  justify-content: center;
  align-items: center;

  .v-input {
    max-width: 15.3em;
    margin: 0 2em;
  }

  ::v-deep .v-select {
    .v-input__append-inner {
      display: none;
    }
    .v-select__selections {
      .v-select__selection {
        width: 100%;
        margin: 0;
        text-align: center;
        max-width: 100%;
      }
    }
  }
}
</style>
<script>
import BarChart from '../components/BarChart.vue';
import {
  parse,
  isFuture,
  differenceInSeconds,
  eachDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  isSameDay,
  startOfYear,
  endOfYear,
  subYears,
  subWeeks,
  subDays,
  getISODay,
  subMonths,
  getYear
} from 'date-fns';
export default {
  data() {
    return {
      now: new Date(),
      interval: null,
      showDuration: true,
      showEarnings: true,
      durationColor: '#333333',
      overtimeColor: '#0e9e57',
      undertimeColor: '#B71C1C',
      earningsColor: 'rgba(53, 148, 53, 0.5)',
      period: 0,
      loading: false,
      selectedPeriod: 0,
      selectedMonth: 0,
      barChartKey: 0,
    };
  },
  mounted() {
    this.interval = setInterval(this.refreshNow, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  components: {
    BarChart,
  },
  computed: {
    targetDailyHours() {
      return this.user && this.user.target_daily_hours
        ? this.user.target_daily_hours
        : 8;
    },
    user() {
      return this.$store.getters['profile/me'];
    },
    visibleDays() {
      if (this.period === 0) {
        return eachDay(
          this.weeks[this.selectedPeriod].start,
          this.weeks[this.selectedPeriod].end
        );
      } else if (this.period === 1) {
        return eachDay(
          this.months[this.selectedPeriod].start,
          this.months[this.selectedPeriod].end
        );
      } else {
        return eachDay(
            this.years[this.selectedPeriod].start,
            this.years[this.selectedPeriod].end
        );
      }
    },
    weeks() {
      let weeks = [
        {
          id: 0,
          start: subDays(this.now, 6),
          end: this.now,
          text: 'Letzte 7 Tage',
        },
        {
          id: 1,
          start: startOfWeek(this.now, { weekStartsOn: 1 }),
          end: endOfWeek(this.now, { weekStartsOn: 1 }),
          text: 'Diese Woche',
        },
      ];
      for (let id = 2; id <= 52; id++) {
        const date = subWeeks(this.now, id - 1);
        let week = {
          id: id,
          start: startOfWeek(date, { weekStartsOn: 1 }),
          end: endOfWeek(date, { weekStartsOn: 1 }),
        };
        week.text =
          this.$date(week.start, 'dd.MM.yyyy') +
          ' - ' +
          this.$date(week.end, 'dd.MM.yyyy');
        weeks.push(week);
      }
      return weeks;
    },
    months() {
      let id;
      let months = [];
      for (id = 0; id <= 12; id++) {
        const date = subMonths(this.now, id);
        let month = {
          id: id,
          start: startOfMonth(date),
          end: endOfMonth(date),
        };
        month.text =
          [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
          ][this.$date(month.start, 'M') - 1] +
          ' ' +
          this.$date(month.start, 'yyyy');
        months.push(month);
      }
      return months;
    },
    years() {
      let id;
      let years = [];
      for (id = 0; id <= 5; id++) {
        const date = subYears(this.now, id);
        let year = {
          id: id,
          start: startOfYear(date),
          end: endOfYear(date),
        };
        year.text = getYear(date);
        years.push(year);
      }
      return years;
    },
    summaries() {
      return this.$store.getters['summaries/list'];
    },
    workDaysUntilNow() {
      return this.visibleDays.filter((d) => {
        if (isFuture(d)) {
          return false;
        }
        const excludedWeekdays =
          this.user && this.user.excluded_weekdays
            ? this.user.excluded_weekdays
            : [6, 7];
        const isoDay = getISODay(d);
        return !excludedWeekdays.some((ew) => ew === isoDay);
      }).length;
    },
    dailyAverageDuration() {
      if (!this.workDaysUntilNow || this.loading) {
        return 0;
      }
      return this.totalDuration / this.workDaysUntilNow;
    },
    dailyAverageEarnings() {
      if (!this.workDaysUntilNow || this.loading) {
        return 0;
      }
      return this.totalEarnings / this.workDaysUntilNow;
    },
    overtime() {
      if (!this.workDaysUntilNow || this.loading) {
        return 0;
      }
      const target = this.targetDailyHours * 60 * 60 * this.workDaysUntilNow;
      return this.totalDuration - target;
    },
    overearnings() {
      if (!this.workDaysUntilNow || !this.user || this.loading) {
        return 0;
      }
      const target = this.user.target_daily_earnings * this.workDaysUntilNow;
      return this.totalEarnings - target;
    },
    totalDuration() {
      let duration = parseFloat(
        this.summaries.reduce(function(a, b) {
          return a + b['duration'];
        }, 0)
      );
      if (
        this.selectedPeriod === 0 &&
        this.current_track.start &&
        !this.current_track.end
      ) {
        let x = parse(new Date(this.current_track.start));
        let y = parse(this.now);
        duration += differenceInSeconds(y, x);
      }
      return duration;
    },
    totalEarnings() {
      let earnings = parseFloat(
        this.summaries.reduce(function(a, b) {
          return a + b['earnings'];
        }, 0)
      );
      if (
        this.selectedPeriod === 0 &&
        this.current_track.start &&
        !this.current_track.end
      ) {
        let x = parse(new Date(this.current_track.start));
        let y = parse(this.now);
        const currentHours = differenceInSeconds(y, x) / 60 / 60;
        earnings += currentHours * this.current_track.task.hourly_rate;
      }
      return earnings;
    },
    current_track() {
      return this.$store.getters['tracks/current'];
    },
    chart_options() {
      const app = this;
      return {
        annotation: {
          drawTime: 'afterDatasetsDraw',
          annotations: [
            {
              drawTime: 'afterDraw',
              id: 'target_daily_hours',
              type: 'line',
              mode: 'horizontal',
              scaleID: 'duration',
              borderDash: [4, 4],
              value: this.targetDailyHours,
              borderColor: this.durationColor + '70',
              borderWidth: 1,
              label: {
                content: 'Ziel Stunden',
                fontColor: this.durationColor,
                enabled: true,
                position: 'left',
                backgroundColor: '#fff',
                xPadding: 4,
                yPadding: 3,
                fontSize: 8,
              },
            },
            {
              drawTime: 'afterDraw',
              id: 'target_daily_earnings',
              type: 'line',
              mode: 'horizontal',
              scaleID: 'earnings',
              borderDash: [4, 4],
              value: this.user ? this.user.target_daily_earnings : 640,
              borderColor: this.durationColor + '70',
              borderWidth: 1,
              label: {
                content: 'Ziel Einnahmen',
                fontColor: this.earningsColor,
                enabled: true,
                position: 'left',
                backgroundColor: '#fff',
                xPadding: 4,
                yPadding: 3,
                fontSize: 8,
              },
            },
          ],
        },
        animation: {
          duration: 0,
        },
        responsive: true,
        tooltips: {
          titleFontColor: '#000000',
          backgroundColor: '#fff',
          bodyFontColor: '#000',
          titleFontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const axis = tooltipItem.datasetIndex;
              if (axis === 1) {
                const day = tooltipItem.index;
                let duration = parseFloat(data.datasets[1].data[day]);
                const overtime = data.datasets[2].data[day];
                if (overtime) {
                  duration += parseFloat(overtime);
                }
                return app.formatHours(duration).substring(0, 5) + ' Stunden';
              } else if (axis === 0) {
                return app.formatEarnings(tooltipItem.yLabel) + ' €';
              } else if (axis === 2) {
                return tooltipItem.yLabel
                  ? app.formatHours(tooltipItem.yLabel).substring(0, 5) +
                      ' Überstunden'
                  : null;
              } else {
                return tooltipItem.yLabel;
              }
            },
          },
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                color: '#fff',
                zeroLineColor: '#eeeeee',
              },
              ticks: {
                fontColor: '#aaa',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              id: 'earnings',
              type: 'linear',
              position: 'right',
              gridLines: {
                color: '#fff',
                zeroLineColor: '#fff',
              },
              ticks: {
                stepSize: 100,
                suggestedMax: this.user ? this.user.target_daily_earnings : 640,
                beginAtZero: true,
                fontColor: '#aaa',
              },
            },

            {
              stacked: true,
              id: 'duration',
              type: 'linear',
              position: 'left',
              gridLines: {
                color: '#eeeeee',
                zeroLineColor: '#eeeeee',
              },
              ticks: {
                stepSize: 1,
                suggestedMax: this.targetDailyHours,
                beginAtZero: true,
                fontColor: '#aaa',
                zeroLineColor: '#eeeeee',
              },
            },
          ],
        },
        legend: {
          display: this.showLegend,
        },
        maintainAspectRatio: false,
      };
    },
    barChartdata() {
      const canvas = document.createElement('canvas');
      let gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 700);
      gradient.addColorStop(0, this.earningsColor);
      gradient.addColorStop(0.85, 'rgba(202, 97, 8, 0.1)');
      gradient.addColorStop(1, 'rgba(202, 97, 8, 0)');
      const barChartdata = {
        labels: [],
        datasets: [
          {
            type: 'line',
            steppedLine: false,
            drawTicks: false,
            label: 'Einnahmen €',
            borderColor: 'transparent',
            pointBorderColor: 'transparent',
            pointBackgroundColor: this.showEarnings
              ? this.earningsColor
              : 'transparent',
            backgroundColor: this.showEarnings ? gradient : 'transparent',
            yAxisID: 'earnings',
            pointRadius: 0,
            borderWidth: 2,
            pointHoverRadius: 15,
            data: [],
            order: 1,
          },
          {
            stack: 'duration',
            categoryPercentage: 0.35,
            barPercentage: 1,
            label: 'Stunden',
            backgroundColor: this.showDuration
              ? this.durationColor
              : 'transparent',
            yAxisID: 'duration',
            data: [],
            order: 2,
            fill: false,
          },
          {
            stack: 'duration',
            categoryPercentage: 0.35,
            barPercentage: 1,
            label: 'Übertunden',
            backgroundColor: this.showDuration
              ? this.overtimeColor
              : 'transparent',
            yAxisID: 'duration',
            data: [],
            order: 2,
            fill: false,
          },
        ],
        backgroundColor: [],
      };
      for (const day of this.visibleDays) {
        const summary = this.summaries.find((s) =>
          isSameDay(parse(s.date), parse(day))
        );
        if (this.period === 0) {
          barChartdata.labels.push(
            [
              'Montag',
              'Dienstag',
              'Mittwoch',
              'Donnerstag',
              'Freitag',
              'Samstag',
              'Sonntag',
            ][this.$date(new Date(day), 'i') - 1]
          );
        } else if (this.period === 1) {
          barChartdata.labels.push(this.$date(new Date(day), 'dd'));
        } else {
          barChartdata.labels.push(this.$date(new Date(day), 'dd.MM'));
        }
        const duration =
          summary && summary.duration ? summary.duration / 60 / 60 : null;
        const overtime =
          duration > this.targetDailyHours
            ? duration - this.targetDailyHours
            : 0;
        barChartdata.datasets[1].data.push(duration - overtime);
        barChartdata.datasets[2].data.push(overtime);
        let earnings = 0;
        if (summary && summary.earnings) {
          earnings = parseFloat(summary.earnings).toFixed(2);
        }
        barChartdata.datasets[0].data.push(earnings);
      }
      return barChartdata;
    },
  },
  watch: {
    current_track: {
      deep: true,
      handler(track, old_track) {
        if (track.id !== old_track.id) {
          this.load();
        }
      },
    },
    user: {
      deep: true,
      handler() {
        this.barChartKey = +new Date();
      },
    },
    barChartdata: {
      deep: true,
      handler() {
        this.barChartKey = +new Date();
      },
    },
    selectedPeriod() {
      this.load();
    },
    period: {
      immediate: true,
      handler() {
        this.selectedPeriod = 0;
        this.load();
      },
    },
  },
  methods: {
    refreshNow() {
      this.now = new Date();
    },
    load() {
      this.loading = true;
      let periodEntries;

      if (this.period === 0) {
        periodEntries = this.weeks;
      }
      if (this.period === 1) {
        periodEntries = this.months;
      }
      if (this.period === 2) {
        periodEntries = this.years;
      }

      this.$store
        .dispatch('summaries/list', {
          filter: {
            date_after: this.$date(
                periodEntries[this.selectedPeriod].start,
                'yyyy-MM-dd'
            ),
            date_before: this.$date(
                periodEntries[this.selectedPeriod].end,
                'yyyy-MM-dd'
            ),
          },
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
